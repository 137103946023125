// eslint-disable-next-line no-undef
const FORM_URL = data.ajax_url
const captchaNum1 = Math.floor(Math.random() * 10) + 1
const captchaNum2 = Math.floor(Math.random() * 10) + 1

// Generic function to handle modal opening with dynamic content
const openModal = (modal, contentSelector) => {
    const content = modal.querySelector(contentSelector)

    if (content) {
        modal.classList.add('is-opened')
    }
}

// Specific logic for handling form modals (can extend or reuse for other modals)
const openFormModal = (modal, titleData, captcha1, captcha2) => {
    const modalTitle = modal.querySelector('.olap-form__heading')
    const { title1, title2 } = titleData
    const form = modal.querySelector('.main-form')

    if (modalTitle && form) {
        modalTitle.textContent = title2 ? title2 : title1
        form.querySelector('.check-text').textContent = `Antispam: ${captcha1} + ${captcha2} = ?`

        addHiddenCaptchaInputs(form, captcha1, captcha2)
    }
}

// Utility to create hidden captcha inputs and append to the form
const addHiddenCaptchaInputs = (form, num1, num2) => {
    const input1 = createHiddenInput('encoded_num1', btoa(num1.toString()))
    const input2 = createHiddenInput('encoded_num2', btoa(num2.toString()))

    form.appendChild(input1)
    form.appendChild(input2)
}

// Utility to create hidden input elements
const createHiddenInput = (name, value) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value

    return input
}

// Event handler for modal open
const handleModalOpen = (event) => {
    const target = event.target
    const href = target.getAttribute('href')

    if (href && href === '#modal') {
        event.preventDefault()
        const modal = document.querySelector('.js-modal')

        openModal(modal, '.main-form')
        openFormModal(
            modal,
            {
                title1: modal.getAttribute('data-title1'),
            }, captchaNum1, captchaNum2)
    }
    if (href && href === '#modal-ask') {
        event.preventDefault()
        const modal = document.querySelector('.js-modal')

        openModal(modal, '.main-form')
        openFormModal(modal,
            {
                title2: modal.getAttribute('data-title2'),
            },
            captchaNum1,
            captchaNum2,
        )
    }
}

const removeHiddenCaptchaInputs = (modal) => {
    const captchaHiddenInputs = modal.querySelectorAll(
        'input[name="encoded_num1"], input[name="encoded_num2"]',
    )
    if (captchaHiddenInputs) {
        captchaHiddenInputs.forEach((input) => input.remove())
    }
}

const handleModalCloseButton = (event) => {
    const button = event.target.closest('.olap-modal-close')
    if (button) {
        const modal = button.closest('.olap-modal')
        if (modal) {
            modal.classList.remove('is-opened')
            removeHiddenCaptchaInputs(modal)
        }
    }
}

const handleModalCloseEscapeButton = () => {
    document.querySelectorAll('.olap-modal').forEach((modal) => {
        modal.classList.remove('is-opened')
    })
}

const handleModalCloseBackground = (event) => {
    const modal = event.target
    if (
        (modal.classList.contains('js-modal') || modal.classList.contains('js-benefits-modal')) &&
        modal.classList.contains('is-opened')
    ) {
        modal.classList.remove('is-opened')
        removeHiddenCaptchaInputs(modal)
    }
}

const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    const inputFileText = event.target
        .closest('.olap-form__group--file .input-file')
        .querySelector('.input-file-text')
    inputFileText.innerHTML = file.name
}

const handleSelectDropdowns = () => {
    const selectElements = document.querySelectorAll('.olap-form__group--select .select')

    selectElements.forEach((select) => {
        const selectHead = select.querySelector('.select__head')
        const selectList = select.querySelector('.select__list')

        selectHead.addEventListener('click', () => {
            const isOpen = selectHead.classList.contains('open')
            closeAllSelects()
            if (!isOpen) {
                selectHead.classList.add('open')
                selectList.classList.add('open')
            }
        })

        selectList.addEventListener('click', (event) => {
            if (event.target.classList.contains('select__item')) {
                const selectedItem = event.target
                const selectHeadElement = selectedItem
                    .closest('.olap-form__group--select .select')
                    .querySelector('.select__head')

                selectHeadElement.classList.remove('open')
                selectList.classList.remove('open')
                selectHeadElement.textContent = selectedItem.textContent
                selectHeadElement.previousElementSibling.value = selectedItem.textContent
            }
        })
    })

    document.addEventListener('click', (event) => {
        if (!event.target.closest('.olap-form__group--select .select')) {
            closeAllSelects()
        }
    })

    const closeAllSelects = () => {
        document.querySelectorAll('.select__head.open').forEach((head) => {
            head.classList.remove('open')
        })
        document.querySelectorAll('.select__list.open').forEach((list) => {
            list.classList.remove('open')
        })
    }
}

const validateForm = (form) => {
    let isValid = true
    const errorElement = form.querySelector('.olap-form__errors')
    const inputs = form.querySelectorAll(
        'input[type="text"], input[type="email"], input[type="tel"]',
    )
    inputs.forEach((input) => {
        if (!input.value.trim()) {
            input.classList.add('has-error')
            isValid = false
        } else {
            input.classList.remove('has-error')
        }
    })

    const emailInput = form.querySelector('input[name="email"]')
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    if (!emailPattern.test(emailInput.value.trim())) {
        emailInput.classList.add('has-error')
        isValid = false
    } else {
        emailInput.classList.remove('has-error')
    }

    const phoneInput = form.querySelector('input[type="tel"]')
    const phonePattern = /^\+?[0-9]+$/
    if (!phonePattern.test(phoneInput.value.trim())) {
        phoneInput.classList.add('has-error')
        isValid = false
    } else {
        phoneInput.classList.remove('has-error')
    }

    const fileInput = form.querySelector('input[type="file"]')
    const filePattern = /^.+\.(docx?|pdf|txt)$/
    const fileSizeLimit = 26214400
    const file = fileInput.files[0]
    if (file) {
        if (!filePattern.test(file.name.trim()) || file.size > fileSizeLimit) {
            fileInput.classList.add('has-error')
            isValid = false
        } else {
            fileInput.classList.remove('has-error')
        }
    } else {
        fileInput.classList.remove('has-error')
    }

    const checkInput = form.querySelector('input[name="check"]')
    if (checkInput.value != captchaNum1 + captchaNum2) {
        checkInput.classList.add('has-error')
        isValid = false
    } else {
        checkInput.classList.remove('has-error')
    }

    const checkbox = form.querySelector('input[type="checkbox"]')
    if (!checkbox.checked) {
        checkbox.classList.add('has-error')
        isValid = false
    } else {
        checkbox.classList.remove('has-error')
    }

    if (!isValid) {
        errorElement.classList.add('visible')
    } else {
        errorElement.classList.remove('visible')
    }

    return isValid
}

const handleFormSubmit = async (event) => {
    event.preventDefault()
    const form = event.target

    // Check if body already has loading class
    if (document.body.classList.contains('is-sending')) {
        return
    }

    if (!validateForm(form)) {
        const outputMessage = document.querySelector('.olap-form__errors')
        outputMessage.classList.remove('success')
        outputMessage.classList.add('error')
        // eslint-disable-next-line no-undef
        outputMessage.textContent = data.messages.errorMessage

        return
    }

    // Add loading class
    document.documentElement.classList.add('is-sending')

    const formData = new FormData(form)

    try {
        const response = await fetch(FORM_URL, {
            method: 'POST',
            body: formData,
        })

        // Remove loading class
        document.documentElement.classList.remove('is-sending')

        if (!response.ok) {
            throw new Error('Network response was not ok')
        }

        const result = await response.json()
        handleFormResponse(result, form)
    } catch (error) {
        handleFormError(form)
    }
}

const handleFormResponse = (result, form) => {
    if (result.success) {
        // eslint-disable-next-line no-undef
        let message = data.messages.successMessage
        if (form.getAttribute('data-success-message') != '') {
            message = form.getAttribute('data-success-message')
        }
        form.reset()
        handleOutputMessage(form, true, message)
    } else {
        // eslint-disable-next-line no-undef
        handleOutputMessage(form, false, data.messages.errorMessage)
    }
}

const handleFormError = (form) => {
    // Remove loading class
    document.documentElement.classList.remove('is-sending')

    // Hide form and show error message
    // eslint-disable-next-line no-undef
    handleOutputMessage(form, false, data.messages.errorMessage)
}

const handleOutputMessage = (form, success, message) => {
    const outputMessage = document.querySelector('.form-row__output')

    if (success) {
        outputMessage.classList.remove('error')
        outputMessage.classList.add('success')
    } else {
        outputMessage.classList.remove('success')
        outputMessage.classList.add('error')
    }

    form.classList.add('hidden')
    outputMessage.innerHTML = message
    outputMessage.classList.remove('hidden')
    outputMessage.classList.add('visible')
}

export {
    openModal,
    handleModalOpen,
    handleModalCloseButton,
    handleModalCloseEscapeButton,
    handleModalCloseBackground,
    handleFileInputChange,
    handleSelectDropdowns,
    handleFormSubmit,
}
